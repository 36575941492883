import { PortalHost } from '@gorhom/portal';
import { DateString, dateStringUtils, Worker, WorkScheduleInterval } from '@mero/api-sdk';
import { WorkingScheduleDay } from '@mero/api-sdk/src/pro/workingSchedule/workingScheduleDay';
import {
  Body,
  Button,
  Checkbox,
  colors,
  FormCard,
  H1,
  HSpacer,
  Icon,
  MeroHeader,
  Row,
  SmallBody,
  Title,
  useShowError,
  useToast,
} from '@mero/components';
import { capitalize } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/NonEmptyArray';
import { flow, pipe } from 'fp-ts/lib/function';
import { isUndefined } from 'lodash';
import { DateTime, IANAZone } from 'luxon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';

import FloatMenu, { Ref as FloatMenuRefu } from '../../../../../../components/FloatMenu';
import ModalScreenContainer from '../../../../../../components/ModalScreenContainer';
import SelectDateTimeModal from '../../../../../../components/SelectDateTimeModal';
import Select from '../../../../../../components/shared/Select';
import InputWithLabel from '@mero/components/lib/components/InputWithLabel';
import Column from '@mero/components/lib/components/Layout/Column';
import SafeAreaView from '@mero/components/lib/components/SafeAreaView';
import Spacer from '@mero/components/lib/components/Spacer';

import { StackScreenProps } from '@react-navigation/stack';

import useGoBack from '../../../../../../hooks/useGoBack';

import { meroApi } from '../../../../../../contexts/AuthContext';
import { CurrentBusiness, CurrentBusinessProps } from '../../../../../../contexts/CurrentBusiness';
import { ScheduleContext } from '../../../../../../contexts/ScheduleContext';
import { AuthorizedStackParamList, ScheduleStackParamList } from '../../../../../../types';
import { logCatch } from '../../../../../../utils/log';
import {
  convertNumberToDay,
  EMPTY_WEEK_SCHEDULE,
  formatSchedule,
  generateDeleteRepeatText,
  getDayName,
} from '../../../../../../utils/schedule';
import { nameGenerator } from '../../../../../../utils/string';
import { DEFAULT_TIMEZONE } from '../../../../../../utils/time';
import InvitedProWarning from '../InvitedProWarning';
import ConfirmOverlapExceptionsModal from './ConfirmOverlapExceptionsModal';
import ConfirmOverlapModal from './ConfirmOverlapModal';
import DayView from './DayView';
import DeleteScheduleModal from './DeleteScheduleModal';
import WeekView from './WeekView';

const REPEAT_OPTIONS = [
  {
    label: 'none',
    value: 'none',
  },
  {
    label: 'weekly',
    value: 'weekly',
  },
  {
    label: '2weeks',
    value: '2weeks',
  },
] as const;

const EMPTY_DAY: Partial<WorkScheduleInterval>[] = [{}];

const EMPTY_WEEK: { active: boolean; intervals: [Partial<WorkScheduleInterval>] }[] = Array.from({ length: 7 }, () => ({
  active: false,
  intervals: [{}],
}));

type Props = StackScreenProps<AuthorizedStackParamList & ScheduleStackParamList, 'SetSchedule'> & CurrentBusinessProps;

const SetScheduleScreen: React.FC<Props> = ({ navigation, route, page }) => {
  const { t } = useTranslation('pros');

  const toast = useToast();
  const showError = useShowError();
  const goBack = useGoBack();

  const { workerId, ...type } = route.params;

  const [scheduleState, { init, updateSchedule }] = ScheduleContext.useContext();

  const floatMenuStartRef = React.useRef<FloatMenuRefu>(null);
  const floatMenuEndRef = React.useRef<FloatMenuRefu>(null);

  const [isSaving, setIsSaving] = React.useState(false);

  const timezone = React.useMemo(() => IANAZone.create('Europe/Bucharest'), []);

  const minDateStart = React.useMemo(() => DateTime.now().setZone(timezone).startOf('day'), []);
  const [startDate, setStartDate] = React.useState<DateTime>(DateTime.now().setZone(timezone));
  const [startDateSetByUser, setStartDateSetByUser] = React.useState(false);

  const [repeat, setRepeat] = React.useState<'none' | 'weekly' | '2weeks'>('none');

  const [showOverlapsBasicModal, setShowOverlapsBasicModal] = React.useState(false);
  const [showOverlapsExceptionsModal, setShowOverlapsExceptionsModal] = React.useState(false);
  const [showDeleteExceptionsModal, setShowDeleteExceptionsModal] = React.useState(false);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);
  const setIsDirtyCallback = React.useCallback(() => setIsDirty(true), []);

  const pro = React.useMemo(() => page.workers.find((w) => w._id === workerId), [workerId]);
  const pros = React.useMemo(() => {
    if (!pro) return [];

    return [
      {
        label: nameGenerator(pro.user, t('noName')),
        value: pro._id,
      },
    ];
  }, [page.workers]);

  const repeatOptions = React.useMemo(() => REPEAT_OPTIONS.map((o) => ({ ...o, label: t(o.label) })), []);

  const checkEndDate = (start: DateTime) => {
    if (endDate && endDate < start) {
      setEndDate(start.plus({ day: 1 }));
    }
  };

  React.useEffect(() => {
    if (scheduleState.type === 'New' && pro) {
      init({
        pageId: page.details._id,
        workerId: pro._id,
        date: type.day ? type.day : dateStringUtils.fromDate(DateTime.now().toJSDate()),
      });
    } else if (scheduleState.type === 'Loaded') {
      const { schedule } = scheduleState;
      if (type.day) {
        const start = DateTime.fromJSDate(dateStringUtils.toDate(type.day, DEFAULT_TIMEZONE), {
          zone: timezone,
        }).startOf('day');
        if (type.type === 'new_interval' || type.type === 'edit_interval' || schedule.type === 'SpecificDate') {
          setRepeat('none');
        } else {
          if (schedule.weekSchedules.length === 1) {
            setRepeat('weekly');
          } else {
            setRepeat('2weeks');
          }
        }
        setStartDate(start);
      } else {
        const startDate = schedule.type === 'SpecificDate' ? schedule.date : schedule.start;
        const start = DateTime.fromJSDate(dateStringUtils.toDate(startDate, DEFAULT_TIMEZONE), {
          zone: timezone,
        }).startOf('day');
        setStartDate(start);

        const repeat =
          schedule.type === 'SpecificDate' ? 'none' : schedule.weekSchedules.length === 1 ? 'weekly' : '2weeks';

        setRepeat(repeat);
      }
    } else if (scheduleState.type === 'Failed') {
      showError(scheduleState.error);
      goBack();
    }
  }, [scheduleState.type, pro, type.day]);

  const dayIntervals = React.useMemo(() => {
    if (scheduleState.type !== 'Loaded') {
      return EMPTY_DAY;
    }

    const { schedule } = scheduleState;

    if (schedule.type === 'SpecificDate' && schedule.daySchedule.active) {
      return type.day && type.type === 'new_interval'
        ? [...schedule.daySchedule.intervals, {}]
        : schedule.daySchedule.intervals;
    }

    if (schedule.type === 'Weekly') {
      const weekSchedule = schedule.weekSchedules[scheduleState.weekIndex];
      const dayOfWeek = getDayName(startDate);
      return weekSchedule[dayOfWeek]?.active
        ? type.day && type.type === 'new_interval'
          ? [...weekSchedule[dayOfWeek].intervals, {}]
          : weekSchedule[dayOfWeek].intervals
        : EMPTY_DAY;
    }

    return EMPTY_DAY;
  }, [scheduleState.type, startDate, type]);

  const weekIntervals = React.useMemo(() => {
    if (scheduleState.type !== 'Loaded') {
      return EMPTY_WEEK;
    }

    const { schedule } = scheduleState;

    if (schedule.type === 'SpecificDate') {
      return EMPTY_WEEK.map((day, i) => {
        const weekday = startDate.weekday - 1 < 0 ? 6 : startDate.weekday - 1;
        if (i !== weekday || !schedule.daySchedule.active) {
          return day;
        }

        return {
          active: true,
          intervals:
            type.day && type.type === 'new_interval'
              ? [...schedule.daySchedule.intervals, {}]
              : schedule.daySchedule.intervals,
        };
      });
    }

    if (schedule.type === 'Weekly') {
      const weekSchedule = schedule.weekSchedules[scheduleState.weekIndex];
      return EMPTY_WEEK.map((day, i) => {
        const dayOfWeek = convertNumberToDay(i);
        if (!weekSchedule[dayOfWeek]?.active) {
          return day;
        }

        return {
          active: true,
          intervals:
            type.day && type.type === 'new_interval'
              ? [...weekSchedule[dayOfWeek].intervals, {}]
              : weekSchedule[dayOfWeek].intervals,
        };
      });
    }

    return EMPTY_WEEK;
  }, [scheduleState.type, startDate, type]);

  const [endDate, setEndDate] = React.useState<DateTime>();

  React.useEffect(() => {
    if (scheduleState.type !== 'Loaded') {
      return;
    }

    const { schedule } = scheduleState;

    if (schedule.type === 'SpecificDate') {
      return setEndDate(undefined);
    }

    if (schedule.type === 'Weekly' && schedule.end) {
      return setEndDate(DateTime.fromJSDate(DateString.toDate(schedule.end, DEFAULT_TIMEZONE), { zone: timezone }));
    }

    setEndDate(undefined);
  }, [scheduleState.type]);

  const toggleEndDate = () => {
    if (endDate) {
      setEndDate(undefined);
    } else {
      setEndDate(startDate.plus({ day: 1 }));
    }
  };

  const saveChanges = async (override?: boolean, overrideExceptions?: boolean) => {
    if (!pro || scheduleState.type !== 'Loaded') {
      return;
    }
    const workingSchedule = formatSchedule({
      start: startDate,
      end: endDate,
      schedule: scheduleState.schedule,
      index: scheduleState.weekIndex,
      repeat,
      startDateSetByUser,
    });
    try {
      setIsSaving(true);
      if (isUndefined(override)) {
        const { scheduleOverlaps, specificDateSchedules } = await meroApi.pro.workingSchedule
          .checkWorkingScheduleOverlaps({
            pageId: page.details._id,
            workerIds: [pro._id, ...scheduleState.selectedExtraPros],
            workingSchedule,
          })
          .catch(logCatch('checkWorkingScheduleOverlaps'));

        if (scheduleOverlaps) {
          if (specificDateSchedules.length === 0 || scheduleState.selectedExtraPros.length > 0) {
            return setShowOverlapsBasicModal(true);
          } else {
            return setShowOverlapsExceptionsModal(true);
          }
        }
      }

      await meroApi.pro.workingSchedule
        .editWorkingSchedule({
          pageId: page.details._id,
          workerIds: [pro._id, ...scheduleState.selectedExtraPros],
          workingSchedule,
          overrideSpecificDaySchedules: Boolean(overrideExceptions),
          startDateSetByUser,
          workerScheduleId: scheduleState.schedule._id,
        })
        .catch(logCatch('editWorkerSchedule'));

      toast.show({
        type: 'success',
        text: t('scheduleSaved'),
      });
      goBack();
    } catch (error) {
      showError(error);
    } finally {
      setIsSaving(false);
    }
  };

  const generateSpecificDaySchedule = (dayIntervals: WorkScheduleInterval[]) => {
    if (scheduleState.type !== 'Loaded' || dayIntervals.length === 0) {
      return;
    }
    updateSchedule({
      ...scheduleState.schedule,
      type: 'SpecificDate',
      date: dateStringUtils.fromDate(startDate.toJSDate()),
      daySchedule: {
        active: true,
        intervals: dayIntervals as Nea.NonEmptyArray<WorkScheduleInterval>,
      },
    });
  };

  const generateWeekSchedule = (weekIntervals: WorkingScheduleDay[]) => {
    if (scheduleState.type !== 'Loaded') {
      return;
    }
    const weekSchedule = weekIntervals.reduce((acc, day, i) => {
      const dayOfWeek = convertNumberToDay(i);
      if (!day.active) {
        return acc;
      }

      return {
        ...acc,
        [dayOfWeek]: day,
      };
    }, EMPTY_WEEK_SCHEDULE);

    updateSchedule({
      ...scheduleState.schedule,
      type: 'Weekly',
      start: dateStringUtils.fromDate(startDate.toJSDate()),
      end: endDate ? dateStringUtils.fromDate(endDate.toJSDate()) : undefined,
      weekSchedules:
        scheduleState.schedule.type === 'Weekly'
          ? scheduleState.schedule.weekSchedules.map((defaultWeek, i) =>
              i === scheduleState.weekIndex ? weekSchedule : defaultWeek,
            )
          : [weekSchedule],
    });
  };

  const onDeleteSchedule = async (override?: boolean) => {
    if (!pro || scheduleState.type !== 'Loaded' || !scheduleState.schedule._id) {
      return;
    }

    const workingSchedule = formatSchedule({
      start: startDate,
      end: endDate,
      schedule: scheduleState.schedule,
      index: scheduleState.weekIndex,
      repeat,
      startDateSetByUser,
    });

    if (isUndefined(override)) {
      const { scheduleOverlaps, specificDateSchedules } = await meroApi.pro.workingSchedule
        .checkWorkingScheduleOverlaps({
          pageId: page.details._id,
          workerIds: [pro._id, ...scheduleState.selectedExtraPros],
          workingSchedule,
        })
        .catch(logCatch('checkWorkingScheduleOverlaps'));

      if (scheduleOverlaps && specificDateSchedules.length > 0) {
        return setShowDeleteExceptionsModal(true);
      }

      return setShowDeleteModal(true);
    }

    if (workingSchedule.type === 'Weekly' && workingSchedule.weekSchedules.length > 1) {
      await meroApi.pro.workingSchedule.editWorkingSchedule({
        pageId: page.details._id,
        workerIds: [pro._id],
        workingSchedule: {
          ...workingSchedule,
          weekSchedules: workingSchedule.weekSchedules.map((week, i) =>
            i === scheduleState.weekIndex ? EMPTY_WEEK_SCHEDULE : week,
          ),
        },
        overrideSpecificDaySchedules: Boolean(override),
        workerScheduleId: scheduleState.schedule._id,
        startDateSetByUser,
      });
    } else {
      await meroApi.pro.workingSchedule.deleteWorkerScheduleById({
        pageId: page.details._id,
        workerId: pro._id,
        workerScheduleId: scheduleState.schedule._id,
        interval: {
          from: DateString.fromDate(startDate.toJSDate(), DEFAULT_TIMEZONE),
          to: endDate ? DateString.fromDate(endDate.toJSDate(), DEFAULT_TIMEZONE) : undefined,
        },
        deleteSpecificDaySchedules: Boolean(override),
      });
    }

    goBack();

    toast.show({
      type: 'success',
      text: t('scheduleDeleted'),
    });
  };

  const isDateEditable = React.useMemo(
    () => repeat !== 'none' || !type.day || type.type !== 'new_interval',
    [type.day, repeat],
  );

  React.useEffect(() => {
    if (!isDateEditable) {
      setStartDate(type.day ? DateTime.fromJSDate(dateStringUtils.toDate(type.day, DEFAULT_TIMEZONE)) : DateTime.now());
    }
  }, [isDateEditable]);

  const isInvited = React.useMemo(() => (pro ? Worker.isInvited(pro, page.details) : false), [pro]);

  const onUpdateRepeat = (value: 'none' | 'weekly' | '2weeks') => {
    setRepeat(value);

    if (value === 'none' && scheduleState.type === 'Loaded' && scheduleState.schedule.type === 'Weekly') {
      generateSpecificDaySchedule(dayIntervals.filter(WorkScheduleInterval.JSON.is));
    } else if (value !== 'none' && scheduleState.type === 'Loaded' && scheduleState.schedule.type === 'SpecificDate') {
      generateWeekSchedule(
        weekIntervals.map((day) => {
          if (day.active) {
            const intervals = day.intervals.filter(WorkScheduleInterval.JSON.is);
            if (intervals.length) {
              return {
                active: true,
                intervals: intervals as Nea.NonEmptyArray<WorkScheduleInterval>,
              };
            }

            return {
              active: false,
            };
          }

          return {
            active: false,
          };
        }),
      );
    }
  };

  if (!pro || scheduleState.type !== 'Loaded') {
    return null;
  }

  return (
    <ModalScreenContainer edges={['left', 'top', 'right']}>
      <MeroHeader title={t('setSchedule')} canClose onClose={goBack} />
      <ScrollView>
        <Column style={{ paddingHorizontal: 24 }}>
          <Spacer size={16} />
          <H1>{t('setSchedule')}</H1>
          {isInvited && (
            <>
              <Spacer size={24} />
              <InvitedProWarning />
            </>
          )}
          <Spacer size={24} />
        </Column>
        <FormCard
          dropShaddow
          rounded
          paddings="none"
          style={{ marginHorizontal: 16, paddingHorizontal: 16, paddingVertical: 24 }}
        >
          <InputWithLabel label={t('pro')}>
            <Select items={pros} value={pro._id} editable={false} />
          </InputWithLabel>
          <Spacer size={16} />
          <Row>
            <Column flex={2}>
              <InputWithLabel label={t('repeat')}>
                <Select
                  style={{ height: 47 }}
                  selectStyle={{ color: colors.BLACK }}
                  items={repeatOptions}
                  value={repeat}
                  onChange={flow(onUpdateRepeat, setIsDirtyCallback)}
                  editable={!isInvited}
                />
              </InputWithLabel>
            </Column>
            <HSpacer left={16} />
            <Column flex={2}>
              <InputWithLabel label={repeat === 'none' ? t('date') : t('startingDate')}>
                <FloatMenu
                  ref={floatMenuStartRef}
                  disabled={!isDateEditable || isInvited}
                  button={
                    <Column
                      style={[
                        {
                          flexDirection: 'row',
                          height: 47,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: '#DEE2E6',
                          alignItems: 'center',
                          paddingHorizontal: 14,
                        },
                        (!isDateEditable || isInvited) && { backgroundColor: colors.ATHENS_GRAY },
                      ]}
                    >
                      <Body
                        style={[{ flex: 1 }, (!isDateEditable || isInvited) && { color: '#a9abbf' }]}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {capitalize(startDate.toFormat('ccc dd LLL', { locale: 'ro' }))}
                      </Body>
                      <Icon type="dropdown" />
                    </Column>
                  }
                >
                  <SelectDateTimeModal
                    isModal={false}
                    mode="date"
                    selected={startDate.toJSDate()}
                    onDateSelected={(date) => {
                      const start = DateTime.fromFormat(
                        DateTime.fromJSDate(date).setZone(timezone).toFormat('dd LLL yyyy'),
                        'dd LLL yyyy',
                        { zone: timezone },
                      ).startOf('day');
                      setStartDate(start);
                      setStartDateSetByUser(true);
                      checkEndDate(start);
                      floatMenuStartRef.current?.onDismissMenu();
                    }}
                    onClosePress={() => {
                      floatMenuStartRef.current?.onDismissMenu();
                    }}
                    minDate={minDateStart.toJSDate()}
                    timeZone={timezone}
                  />
                </FloatMenu>
              </InputWithLabel>
            </Column>
          </Row>
          {repeat !== 'none' && !isInvited && (
            <>
              <Spacer size={24} />
              <TouchableOpacity onPress={flow(toggleEndDate)} style={{ flexDirection: 'row' }}>
                <Checkbox value={Boolean(endDate)} onValueChange={flow(toggleEndDate)} />
                <Title style={{ paddingLeft: 12 }}>{t('endDate')}</Title>
              </TouchableOpacity>
              {endDate ? (
                <>
                  <Spacer size={16} />
                  <FloatMenu
                    ref={floatMenuEndRef}
                    button={
                      <Column
                        style={{
                          flexDirection: 'row',
                          height: 47,
                          borderWidth: 1,
                          borderRadius: 5,
                          borderColor: '#DEE2E6',
                          alignItems: 'center',
                          paddingHorizontal: 14,
                        }}
                      >
                        <Body style={{ flex: 1 }} numberOfLines={1} ellipsizeMode="tail">
                          {capitalize(endDate.toFormat('cccc dd LLL yyyy', { locale: 'ro' }))}
                        </Body>
                        <Icon type="dropdown" />
                      </Column>
                    }
                  >
                    <SelectDateTimeModal
                      isModal={false}
                      mode="date"
                      selected={endDate.toJSDate()}
                      onDateSelected={(date) => {
                        const start = DateTime.fromFormat(
                          DateTime.fromJSDate(date).setZone(timezone).toFormat('dd LLL yyyy'),
                          'dd LLL yyyy',
                          { zone: timezone },
                        ).startOf('day');
                        setEndDate(start);
                        floatMenuEndRef.current?.onDismissMenu();
                      }}
                      onClosePress={() => {
                        floatMenuEndRef.current?.onDismissMenu();
                      }}
                      minDate={startDate.plus({ day: 1 }).toJSDate()}
                      timeZone={timezone}
                    />
                  </FloatMenu>
                </>
              ) : null}
            </>
          )}
        </FormCard>
        <Column style={{ paddingHorizontal: 24 }}>
          <Spacer size={24} />
          {repeat === 'none' && (
            <>
              <DayView
                date={startDate.toFormat('yyyy-MM-dd') as DateString}
                initialInterval={dayIntervals}
                updateInterval={flow(generateSpecificDaySchedule, setIsDirtyCallback)}
                disabled={isInvited}
              />
              <Spacer size={8} />
            </>
          )}
          {repeat !== 'none' && (
            <WeekView
              days={weekIntervals}
              updateDays={flow(generateWeekSchedule, setIsDirtyCallback)}
              disabled={isInvited}
            />
          )}
          <Spacer size={16} />

          {page.workers.length > 1 && !isInvited && (
            <>
              <TouchableOpacity style={styles.pros} onPress={() => navigation.navigate('AddExtraPro', { workerId })}>
                <Column>
                  <Title>{t('addExtraPros')}</Title>
                  <SmallBody style={{ color: colors.COMET }}>
                    {scheduleState.selectedExtraPros.length > 0
                      ? t('selectedExtraPros', { count: scheduleState.selectedExtraPros.length })
                      : t('addExtraProsDescription')}
                  </SmallBody>
                </Column>
                <Icon type="next" color={colors.DARK_BLUE} />
              </TouchableOpacity>
              <Spacer size={32} />
            </>
          )}
          {type.day && type.type === 'edit_schedule' && !isInvited && !isDirty && (
            <>
              <TouchableOpacity onPress={() => onDeleteSchedule()}>
                <Title style={{ color: colors.RADICAL_RED, textAlign: 'center' }}>
                  {t(repeat === 'none' ? 'deleteSchedule' : 'deleteScheduleRecurring', {
                    date: startDate.toFormat('cccc dd MMM', { locale: 'ro' }),
                  })}
                </Title>
              </TouchableOpacity>
            </>
          )}

          <Spacer size={96 + 32} />
        </Column>
      </ScrollView>
      <FormCard dropShaddow paddings="button" style={[{ position: 'absolute', left: 0, bottom: 0, right: 0 }]}>
        <SafeAreaView edges={['bottom']}>
          <Button text={t('saveChanges')} onPress={() => saveChanges()} disabled={isSaving || isInvited} />
        </SafeAreaView>
      </FormCard>
      {showOverlapsBasicModal && (
        <ConfirmOverlapModal
          workerId={pro._id}
          onDismiss={() => setShowOverlapsBasicModal(false)}
          onConfirm={flow(
            () => saveChanges(true, false),
            () => setShowOverlapsBasicModal(false),
          )}
        />
      )}
      {showOverlapsExceptionsModal && (
        <ConfirmOverlapExceptionsModal
          type="save"
          workerId={pro._id}
          onDismiss={() => setShowOverlapsExceptionsModal(false)}
          onDonotOverrideExceptions={flow(
            () => saveChanges(true, false),
            () => setShowOverlapsExceptionsModal(false),
          )}
          onOverrideExceptions={flow(
            () => saveChanges(true, true),
            () => setShowOverlapsExceptionsModal(false),
          )}
        />
      )}
      {showDeleteExceptionsModal && (
        <ConfirmOverlapExceptionsModal
          type="delete"
          workerId={pro._id}
          onDismiss={() => setShowOverlapsExceptionsModal(false)}
          onDonotOverrideExceptions={flow(
            () => onDeleteSchedule(false),
            () => setShowOverlapsExceptionsModal(false),
          )}
          onOverrideExceptions={flow(
            () => onDeleteSchedule(true),
            () => setShowOverlapsExceptionsModal(false),
          )}
        />
      )}
      {showDeleteModal && !isInvited ? (
        <DeleteScheduleModal
          title={t('deleteScheduleTitle')}
          description={generateDeleteRepeatText(scheduleState.schedule, scheduleState.weekIndex, t)}
          onConfirm={() => onDeleteSchedule(false)}
          onDismiss={() => setShowDeleteModal(false)}
        />
      ) : null}
      <PortalHost name="SetScheduleScreen" />
    </ModalScreenContainer>
  );
};

const styles = StyleSheet.create({
  pros: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: colors.GEYSER,
    paddingVertical: 12,
    paddingLeft: 14,
    paddingRight: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default pipe(SetScheduleScreen, CurrentBusiness);
